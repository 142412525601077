import React from 'react';
import { LoadingOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Card, Space, Form, Row, Col, Input, Select, Button, Upload, Empty, Tag, message, InputNumber } from 'antd';
import type { GetProp, UploadProps } from 'antd';
import { SelectGroupModal } from '../selectGroup';
import type AnchorEditModel from './model';
import { FullModal, singleUploadObs } from '../../../../utils';
import { observer } from 'mobx-react';
import styles from './index.less';
import getImgUrl from '../../../../utils/firstFrame';
import compressImgOrVideo from '../../../../utils/compress';
import { SortableList } from './sortableList';
import { toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

const OBS_PATH = 'community/avatar/image/';
@observer
export default class AnchorEditor extends React.Component<{ store: AnchorEditModel }> {
  render() {
    const {
      visible,
      setOpusListImage,
      setOpusListVideo,
      loadingSave,

      opusListImage,
      opusListVideo,
      setDeleteOpusList,
      setAddOpusList,
      type,
      setLoadingVideo,
      loadingVideo,
      setExpertType,
      expertType,
      loading,
      setGroupInfo,
      selectGroupStore,
      groupInfo,
      onSave,
      onCancel,
      parentStore,
      setRef,
      setNoteTypeIds,
    } = this?.props?.store || {};
    const uploadImageButton = (
      <button
        style={{
          border: 0,
          background: 'none',
        }}
        type="button"
      >
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>添加图片</div>
      </button>
    );

    const uploadVideoButton = (
      <button
        style={{
          border: 0,
          background: 'none',
        }}
        type="button"
      >
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>添加视频</div>
      </button>
    );

    // 身高、体重验证规则
    const heightAndWeightRules = [
      {
        required: true,
        pattern: /^\d+(\.\d+)?$/,
        message: '请输入数字',
      },
    ];

    return (
      <FullModal
        onCancel={onCancel}
        visible={visible}
        operation={
          <div className={styles.btnBox}>
            <Button
              loading={loadingSave}
              type="primary"
              onClick={onSave}
            >
              保存
            </Button>
          </div>
        }
        title={type === 'add' ? '新增达人' : '达人编辑'}
      >
        <div className={styles.container}>
          <Card
            title="达人信息"
            bordered={false}
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              ref={setRef}
            >
              <Row>
                <Col span={6}>
                  <Form.Item
                    label="达人类型"
                    name="expertType"
                    validateTrigger="onBlur"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(value) => {
                        setExpertType(value);
                      }}
                      options={parentStore.expertTypeOptions}
                      placeholder="请选择达人类型"
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    label="达人微信昵称"
                    name="nickName"
                    validateTrigger="onBlur"
                    rules={[{ required: true }]}
                  >
                    <Input
                      autoComplete="off"
                      placeholder="请输入达人微信昵称"
                      maxLength={99}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="达人头像"
                    required
                    shouldUpdate
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    className={styles.formItem}
                  >
                    {({ getFieldValue, setFieldValue }) => {
                      const avatar = getFieldValue('avatar');
                      return (
                        <Form.Item
                          className={styles.formItem}
                          name="avatar"
                          getValueFromEvent={(e) => avatar}
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: '请上传头像',
                            },
                          ]}
                        >
                          <Upload
                            name="avatar"
                            beforeUpload={beforeUpload}
                            listType="picture-circle"
                            className={styles.avatarUploader}
                            showUploadList={false}
                            customRequest={async (i: any) => {
                              const unit = i.file.name.split(',');
                              const file: any = await compressImgOrVideo(i.file);
                              if (file === false) {
                                return;
                              }
                              const url = await singleUploadObs(file, `${OBS_PATH}${i.file.uid}${unit[unit.length - 1]}`);
                              setFieldValue('avatar', url);
                            }}
                          >
                            {avatar && typeof avatar === 'string' ? (
                              <img
                                src={avatar}
                                alt="avatar"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                }}
                              />
                            ) : (
                              <button
                                style={{
                                  border: 0,
                                  background: 'none',
                                }}
                                type="button"
                              >
                                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                <div style={{ marginTop: 8 }}>上传</div>
                              </button>
                            )}
                          </Upload>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                {['1', '2', '4'].includes(expertType) ? (
                  <Col span={6}>
                    <Form.Item
                      label="主营类目"
                      name="expertMainType"
                      validateTrigger="onBlur"
                      rules={[{ required: true }]}
                    >
                      <Select
                        options={parentStore.expertMainTypeOptions}
                        placeholder="请选择主营类目"
                      />
                    </Form.Item>
                  </Col>
                ) : undefined}
                {['5'].includes(expertType) ? (
                  <Col span={6}>
                    <Form.Item
                      label="主营类目"
                      name="expertMainTypeValue"
                      validateTrigger="onBlur"
                      rules={[{ required: true }]}
                    >
                      <Input
                        autoComplete="off"
                        placeholder="请输入主营类目"
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                ) : undefined}
                {['1', '4'].includes(expertType) ? (
                  <Col span={6}>
                    <Form.Item
                      label="30日橱窗销量"
                      name="thirtyDaysSale"
                      validateTrigger="onBlur"
                    >
                      <Input
                        autoComplete="off"
                        placeholder="请输入橱窗销量"
                      />
                    </Form.Item>
                  </Col>
                ) : undefined}
                {['1', '4'].includes(expertType) ? (
                  <Col span={6}>
                    <Form.Item
                      label="30天GMV"
                      name="thirtyDaysGmv"
                      validateTrigger="onBlur"
                    >
                      <Input
                        autoComplete="off"
                        placeholder="请输入30天的GMV"
                      />
                    </Form.Item>
                  </Col>
                ) : undefined}
                {['2'].includes(expertType) ? (
                  <Col span={6}>
                    <Form.Item
                      label="私域人数"
                      name="privateScale"
                      validateTrigger="onBlur"
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{ width: '100%' }}
                        placeholder="请输入私域人数"
                      />
                    </Form.Item>
                  </Col>
                ) : undefined}
                {['2'].includes(expertType) ? (
                  <Col span={6}>
                    <Form.Item
                      label="销量"
                      name="privateSaleScale"
                      validateTrigger="onBlur"
                    >
                      <Input
                        style={{ width: '100%' }}
                        placeholder="请输入销量"
                      />
                    </Form.Item>
                  </Col>
                ) : undefined}
                {['3'].includes(expertType) ? (
                  <Col span={6}>
                    <Form.Item
                      label="笔记类型"
                      name="noteTypeIds"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: '请选择笔记类型',
                        },
                      ]}
                    >
                      <Select
                        placeholder="请选择笔记类型"
                        mode="multiple"
                        allowClear
                        options={parentStore.noteTypeOptions}
                        onChange={(value) => {
                          if (value?.length > 3) {
                            message.warning('最多选择3个');
                            setNoteTypeIds(value.slice(0, 3));
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                ) : undefined}
                {/* {['3'].includes(expertType) ? (
                  <Col span={6}>
                    <Form.Item
                      label="爆款笔记浏览量"
                      name="noteTopViews"
                      validateTrigger="onBlur"
                    >
                      <Input
                        style={{ width: '100%' }}
                        placeholder="请输入爆款笔记浏览量"
                      />
                    </Form.Item>
                  </Col>
                ) : undefined} */}
                {['1', '3', '4', '5'].includes(expertType) ? (
                  <Col
                    span={6}
                    className={styles.type}
                  >
                    <Form.List
                      initialValue={[{}]}
                      name="funNum"
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          <div style={{ width: '100%' }}>
                            {fields.map((field, index) => (
                              <Form.Item
                                className={styles.formItem}
                                {...(index !== 0 ? { wrapperCol: { offset: 8 } } : {})}
                                label={index === 0 ? '经营平台' : ''}
                                required
                                key={`${field.key}`}
                              >
                                <Form.Item
                                  name={[field.name, 'platformType']}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '请选择经营平台',
                                    },
                                  ]}
                                  noStyle
                                >
                                  <Select
                                    placeholder="请选择"
                                    options={parentStore.platformTypeOptions}
                                  />
                                </Form.Item>
                                <Form.Item
                                  noStyle
                                  className={styles.formItemMargin}
                                  name={[field.name, 'funNum']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '请输入粉丝数量',
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="粉丝数量"
                                    className={styles.input}
                                  />
                                </Form.Item>
                                {['3'].includes(expertType) && (
                                  <Form.Item
                                    noStyle
                                    className={styles.formItemMargin}
                                    name={[field.name, 'linkAndCollectionCount']}
                                    rules={[
                                      {
                                        required: true,
                                        message: '请输入获赞与收藏',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="获赞与收藏"
                                      className={styles.input}
                                    />
                                  </Form.Item>
                                )}
                                {['5'].includes(expertType) && (
                                  <Form.Item
                                    noStyle
                                    className={styles.formItemMargin}
                                    name={[field.name, 'salesCount']}
                                    rules={[
                                      {
                                        required: true,
                                        message: '请输入销量',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="销量"
                                      className={styles.input}
                                    />
                                  </Form.Item>
                                )}
                                <MinusCircleOutlined
                                  style={fields.length <= 1 ? { display: 'none' } : undefined}
                                  className={`dynamic-delete-button ${styles.remove}`}
                                  onClick={() => remove(field.name)}
                                />
                              </Form.Item>
                            ))}
                          </div>
                          <div
                            className={styles.addCard}
                            style={fields.length > 1 ? { display: 'none' } : undefined}
                          >
                            <Button
                              className={styles.add}
                              type="primary"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              添加
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </div>
                        </>
                      )}
                    </Form.List>
                  </Col>
                ) : undefined}
              </Row>
            </Form>
          </Card>

          <Card
            title="群组信息"
            extra={
              <Button
                type="primary"
                onClick={selectGroupStore.onShow}
              >
                添加
              </Button>
            }
            bordered={false}
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            {groupInfo?.length ? (
              <Space className={styles.groupInfo}>
                {groupInfo.map((item, index) => (
                  <Tag
                    onClose={(e) => {
                      e.preventDefault();
                      setGroupInfo(item.anchorGroupId);
                    }}
                    closable
                    key={item.anchorGroupId}
                  >
                    {/* {item.anchorGroupName}（{item.anchorGroupNickName}） */}
                    {item.anchorGroupName}
                  </Tag>
                ))}
              </Space>
            ) : (
              <div className={styles.empty}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </Card>

          <Card
            title="作品信息"
            bordered={false}
            style={{ width: '100%' }}
          >
            <Row>
              <Col span={12}>
                <div className={styles.imageList}>
                  <div>
                    <SortableList
                      useDragHandle
                      axis="xy"
                      // @ts-ignore
                      type={0}
                      items={toJS(opusListImage)}
                      onSortEnd={({ oldIndex, newIndex }) => {
                        setOpusListImage(oldIndex, newIndex);
                      }}
                      removeFile={(url) => {
                        setDeleteOpusList(url, 0);
                      }}
                    />
                  </div>
                  <Upload
                    listType="picture-card"
                    fileList={[]}
                    beforeUpload={beforeUpload}
                    maxCount={8}
                    // multiple
                    customRequest={async (i: any) => {
                      const unit = i.file.name.split(',');
                      const file: any = await compressImgOrVideo(i.file);
                      if (file === false) {
                        return;
                      }

                      const url = await singleUploadObs(file, `anchorManagement/avatar/image/${i.file.uid}${unit[unit.length - 1]}`);
                      setAddOpusList(url, 0);
                    }}
                    onRemove={(values) => {
                      setDeleteOpusList(values.url, 0);
                    }}
                  >
                    {opusListImage && opusListImage.length >= 8 ? null : (
                      <button
                        style={{
                          border: 0,
                          background: 'none',
                        }}
                        type="button"
                      >
                        {loading ? <LoadingOutlined /> : <PlusOutlined />}
                        <div style={{ marginTop: 8 }}>添加图片</div>
                      </button>
                    )}
                  </Upload>
                </div>
                <span>支持 JPG 、 PNG，大小不超过5M</span>
              </Col>
              {/* 达人暂时注释上传视频代码 */}
              {/* <Col span={12}>
                <div className={styles.imageList}>
                  <div>
                    <SortableList
                      useDragHandle
                      axis="xy"
                      // @ts-ignore
                      type={1}
                      items={toJS(opusListVideo)}
                      onSortEnd={({ oldIndex, newIndex }) => {
                        setOpusListVideo(oldIndex, newIndex);
                      }}
                      removeFile={(url) => {
                        setDeleteOpusList(url, 1);
                      }}
                    />
                  </div>
                  <Upload
                    listType="picture-card"
                    fileList={[]}
                    accept=".mp4,.flv,.avi,.wmy,.mov,.webm,.mpeg4,.ts,.mpg,.rm,.rmvb,.mkv,.m4v"
                    beforeUpload={beforeUploadVideo}
                    maxCount={8}
                    // multiple
                    customRequest={async (i: any) => {
                      if (loadingVideo) {
                        return;
                      }
                      setLoadingVideo();
                      const unit = i.file.name.split(',');
                      // const file: any = await compressImgOrVideo(i.file);
                      // if (file === false) {
                      //   setLoadingVideo();
                      //   return;
                      // }
                      const url = await singleUploadObs(i.file, `anchorManagement/avatar/video/${i.file.uid}${unit[unit.length - 1]}`);
                      const thumbFile = await getImgUrl(url);
                      const thumbUrl = await singleUploadObs(thumbFile, `anchorManagement/avatar/video/${thumbFile.name}`);
                      setAddOpusList(url, 1, thumbUrl);
                      setLoadingVideo();
                    }}
                    onRemove={(values) => {
                      setDeleteOpusList(values.url, 1);
                    }}
                  >
                    {opusListVideo && opusListVideo.length >= 8 ? null : (
                      <button
                        style={{
                          border: 0,
                          background: 'none',
                        }}
                        type="button"
                      >
                        {loadingVideo ? <LoadingOutlined /> : <PlusOutlined />}
                        <div style={{ marginTop: 8 }}>{loadingVideo ? '上传中' : '添加视频'}</div>
                      </button>
                    )}
                  </Upload>
                </div>
                <span>支持 mp4,flv,avi,wmy,mov,webm,mpeg4,ts,mpg,rm,rmvb,mkv,m4v 格式，大小不超过 5G</span>
              </Col> */}
            </Row>
          </Card>
        </div>
        <SelectGroupModal store={selectGroupStore} />
      </FullModal>
    );
  }
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp';
  if (!isJpgOrPng) {
    message.error('仅支持上传JPG、PNG、WEBP!');
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('图片必须小于5MB!');
    return false;
  }
  return isJpgOrPng && isLt2M;
};
const beforeUploadVideo = (file: FileType) => {
  const isJpgOrPng = [
    'video/mp4',
    'video/x-flv',
    'video/avi',
    'video/x-ms-wmv',
    'video/quicktime',
    'video/webm',
    'video/mpeg',
    'video/MP2T',
    'video/mpg',
    'application/vnd.rn-realmedia',
    'application/vnd.rn-realmedia-vbr',
    'video/x-matroska',
    'video/x-m4v',
  ].includes(file.type);
  if (!isJpgOrPng) {
    message.error('仅支持上传 mp4,flv,avi,wmy,mov,webm,mpeg4,ts,mpg,rm,rmvb,mkv,m4v 格式!');
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 300;
  if (!isLt2M) {
    message.error('视频必须小于300MB!');
    return false;
  }
  return isJpgOrPng && isLt2M;
};
